<template>
  <div class="solucionados">
    <div id="ayuda-escr" class=" fixed bottom-0 right-0 rounded-3xl bg-white h-48 w-96 m-10 shadow-2xl text-black overflow-hidden z-50" v-show="ayuda">
      <div class="absolute mx-3 my-3 block top-0 right-0 w-4 h-4 cursor-pointer text-white" @click="cerrarAyuda()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path class=" fill-current" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
        </svg>
      </div>
      <div class="bg-gradient-to-r
                  from-blue-900
                  to-gray-800
                  p-2
                  shadow
                  text-xl text-white">
        <span class="font-bold font-sans">Necesitas ayuda?</span>
      </div>
      <div class=" relative block w-10/12 mx-auto my-3">
        <span>Tenemos un Manual de Procesos en el que te explicamos como digitar correctamente tus Cambios, Devoluciones y Reclamos</span>
      </div>
      <a href="https://gestioncasos.juanabonita.com.co/img/Instructivo_GC_SAC-CDR-2022.pdf" download class=" relative table mx-auto w-32 bg-gradient-to-r from-blue-900 to-gray-800 text-white pt-1 rounded-full cursor-pointer shadow-lg">
        <div class=" relative inline-block w-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path class=" fill-current" d="M184 208c0-4.406-3.594-8-8-8S168 203.6 168 208c0 2.062 .2969 23.31 9.141 50.25C179.1 249.6 184 226.2 184 208zM256 0v128h128L256 0zM80 422.4c0 9.656 10.47 11.97 14.38 6.375C99.27 421.9 108.8 408 120.1 388.6c-14.22 7.969-27.25 17.31-38.02 28.31C80.75 418.3 80 420.3 80 422.4zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM292 312c24.26 0 44 19.74 44 44c0 24.67-18.94 44-43.13 44c-5.994 0-11.81-.9531-17.22-2.805c-20.06-6.758-38.38-15.96-54.55-27.39c-23.88 5.109-45.46 11.52-64.31 19.1c-14.43 26.31-27.63 46.15-36.37 58.41C112.1 457.8 100.8 464 87.94 464C65.92 464 48 446.1 48 424.1c0-11.92 3.74-21.82 11.18-29.51c16.18-16.52 37.37-30.99 63.02-43.05c11.75-22.83 21.94-46.04 30.33-69.14C136.2 242.4 136 208.4 136 208c0-22.05 17.95-40 40-40c22.06 0 40 17.95 40 40c0 24.1-7.227 55.75-8.938 62.63c-1.006 3.273-2.035 6.516-3.082 9.723c7.83 14.46 17.7 27.21 29.44 38.05C263.1 313.4 284.3 312.1 287.6 312H292zM156.5 354.6c17.98-6.5 36.13-11.44 52.92-15.19c-12.42-12.06-22.17-25.12-29.8-38.16C172.3 320.6 164.4 338.5 156.5 354.6zM292.9 368C299 368 304 363 304 356.9C304 349.4 298.6 344 292 344H288c-.3438 .0313-16.83 .9687-40.95 4.75c11.27 7 24.12 13.19 38.84 18.12C288 367.6 290.5 368 292.9 368z"/>
          </svg>
        </div>
        <span class="font-bold ml-2 font-sans">Ver Manual</span>
      </a>
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="main-content flex-1 bg-gray-100 mt-12 md:mt-12 pb-24 md:pb-5">
        <div class="bg-gray-800 pt-3">
          <div
            class="
              rounded-tl-3xl
              bg-gradient-to-r
              from-blue-900
              to-gray-800
              p-4
              shadow
              text-2xl text-white
            "
          >
            <h3 class="font-bold pl-2 font-sans">Casos Solucionados</h3>
          </div>
        </div>
        <div class="flex flex-wrap mt-2">
          <div class="p-3">
            <router-link
              to="/Casos/NuevoCaso"
              class="buttonBlue"
              exact
              :style="{ cursor: 'pointer' }"
            >
              <i class="fa fa-plus text-white pr-2 "
                ></i
                ><label exact :style="{ cursor: 'pointer' }" class="font-sans font-medium text-lg">
                  &nbsp;Nuevo</label
              >
            </router-link>
          </div>
          <div class="search-box w-84 flex inline mt-3">
            <input
              v-model="buscardata"
              @keyup="findtext()"
              type="number"
              class="search-input h-9 bg-white"
              placeholder="Buscar por cedula..."
            />

            <button @click="findtext" class="search-button h-9 bg-white">
              <i class="fas fa-search"></i>
            </button>
          </div>
          <div
            v-if="userid"
            class="search-box mx-4 w-84 flex inline grid grid-cols-1 mt-3"
          >
            <select
              id="buscarzona"
              v-model="buscarzona"
              class="border rounded-xl shadow h-9"
              @click="findZona"
            >
              <option disabled selected>Zonas</option>
              <option v-for="(zona, index) in zonas" :value="zona" :key="index">
                {{ zona }}
              </option>
            </select>
          </div>
          <div
            class="search-box ml-4 flex inline grid grid-cols-1 w-14 mt-3"
          >
            <select
              id="buscarzona"
              v-model="tamanoPagina"
              class="border rounded-xl shadow h-9 bg-white"
            >
              <option disabled selected>Casos Por Pagina</option>
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>75</option>
              <option>100</option>
            </select>
          </div>
          <div class="relative block w-44 h-9 ml-4 grid grid-cols-5 mt-3 text-blue-900 ">
            <div class=" table mx-auto cursor-pointer" v-show=" paginaActual >= 2" @click="inicioPag()">
              <i class=" relative flex w-full h-5 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
                  <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path fill="currentColor" d="M77.25 256l137.4-137.4c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0l-160 160c-12.5 12.5-12.5 32.75 0 45.25l160 160C175.6 444.9 183.8 448 192 448s16.38-3.125 22.62-9.375c12.5-12.5 12.5-32.75 0-45.25L77.25 256zM269.3 256l137.4-137.4c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0l-160 160c-12.5 12.5-12.5 32.75 0 45.25l160 160C367.6 444.9 375.8 448 384 448s16.38-3.125 22.62-9.375c12.5-12.5 12.5-32.75 0-45.25L269.3 256z"/>
                </svg>
              </i>
            </div>
            <div class=" table mx-auto cursor-pointer" @click="retrocederPag()" v-show=" paginaActual >= 2">
              <i class=" relative flex w-full h-5 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                  <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path fill="currentColor" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z"/>
                </svg>
              </i>
            </div>
            <div class=" table mx-auto">
              <div class=" relative flex w-full h-9 text-xl mt-1">
                {{ paginaActual }}
              </div>
            </div>
            <div class=" table mx-auto cursor-pointer" @click="avanzarPag()" v-show=" paginaActual < cantPaginas">
              <i class=" relative flex w-full h-5 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                  <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"/>
                </svg>
              </i>
            </div>
            <div class=" table mx-auto cursor-pointer" v-show=" paginaActual < cantPaginas" @click="finPag()">
              <i class=" relative flex w-full h-5 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path fill="currentColor" d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"/>
                </svg>
              </i>
            </div>
          </div>
          <div :key="index" v-for="(caso, index) in paginado" class="w-full p-6">
            <div class="bg-white border-transparent rounded-lg shadow-xl">
              <div
                class="
                  grid grid-cols-3 md:grid-cols-6
                  bg-gradient-to-b
                  from-gray-300
                  to-gray-100
                  text-gray-900
                  border-b-2 border-gray-300
                  rounded-tl-lg rounded-tr-lg
                  p-0
                "
              >
                <div class="text-left w-full w-auto px-1 py-2 my-auto inline-block grid grid-cols-2">
                  <div class="requestViewId inline-block">
                    #{{ caso.numcodigo }}
                  </div>
                  <div
                    v-if="caso.descripcionEstadoReclamos == 'ASIGNADO'"
                    class="table w-auto
                      text-center font-sans font-medium text-md
                      bg-green-600
                      table
                      rounded-lg
                      text-white
                      p-1
                      h-1
                      mt-1
                      inline-block
                    "
                  >
                    <label class="text-sm">
                      {{ caso.descripcionEstadoReclamos }}
                    </label>
                  </div>
                  <div
                    v-if="caso.descripcionEstadoReclamos == 'CERRADO'"
                    class="table w-auto
                      text-center font-sans font-medium text-md
                      bg-red-600
                      table
                      rounded-lg
                      text-white
                      p-1
                      h-1
                      mt-1
                      inline-block
                    "
                  >
                    <label class="text-sm">
                      {{ caso.descripcionEstadoReclamos }}
                    </label>
                  </div>
                </div>
                <div
                  class="text-center w-full rounded-lg text-gray-500 inline-block p-1 my-auto col-span-2 grid grid-cols-12"
                >
                  <div class=" col-span-8">
                    <div class=" grid grid-cols-12">
                      <div class=" col-span-2 userlogo">
                        <div class=" relative table w-full my-auto">
                          <i class="fas fa-user-circle"></i>
                        </div>
                      </div>
                      <div class=" col-span-10 w-full text-left">
                        <label class="text-sm font-sans font-medium">&nbsp; {{ caso.nombreCliente}}</label>
                      </div>
                    </div>
                  </div>
                  <div class=" col-span-4 table my-auto">
                    <i class=" relative inline-block w-5 top-1">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path class=" fill-current" d="M512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM176 128c35.35 0 64 28.65 64 64s-28.65 64-64 64s-64-28.65-64-64S140.7 128 176 128zM272 384h-192C71.16 384 64 376.8 64 368C64 323.8 99.82 288 144 288h64c44.18 0 80 35.82 80 80C288 376.8 280.8 384 272 384zM496 320h-128C359.2 320 352 312.8 352 304S359.2 288 368 288h128C504.8 288 512 295.2 512 304S504.8 320 496 320zM496 256h-128C359.2 256 352 248.8 352 240S359.2 224 368 224h128C504.8 224 512 231.2 512 240S504.8 256 496 256zM496 192h-128C359.2 192 352 184.8 352 176S359.2 160 368 160h128C504.8 160 512 167.2 512 176S504.8 192 496 192z"/>
                      </svg>
                    </i>
                    <label class="text-sm font-sans font-medium">&nbsp; {{ caso.numDocumento}}</label>
                  </div>
                </div>
                <div
                  class=" col-span-2
                    grid grid-cols-12
                    text-center font-sans font-medium text-md
                    w-full
                    rounded-lg
                    text-gray-500
                    p-1
                    my-auto
                    inline-block
                  "
                >
                  <div class=" col-span-8">
                    <i class="fas fa-clock pr-2"></i>
                    <label class="text-sm font-sans font-medium"
                      >&nbsp;
                      {{
                        caso.fecha_Ingreso
                          | moment(
                            "timezone",
                            "America/Bogota",
                            "Do MMMM YYYY, h:mm a"
                          )
                      }}</label
                    >
                  </div>
                  <div class=" col-span-4 table my-auto">
                    <i class="fas fa-map-marked-alt"></i>
                    <label class="text-sm font-sans font-medium">&nbsp;{{ caso.codigoZona }}</label>
                  </div>
                </div>
                <div
                  class=" grid grid-cols-12
                    text-center
                    w-full
                    rounded-lg
                    text-gray-500
                    p-1
                    my-auto
                    inline-block
                  "
                >
                  <div
                    class=" col-span-10
                      text-right
                      table
                      mx-auto
                      rounded-lg
                      inline-block
                    ">
                    <i class="fas fa-book"></i>
                    <label class="text-sm text-sm font-sans font-medium"
                      >&nbsp; Campaña {{ caso.campaña }}</label
                    >
                  </div>
                  <div
                    class=" col-span-2
                      table my-auto
                      mx-auto
                      text-right
                      rounded-lg
                      text-black
                      inline-block
                    "
                  >
                    <i
                      :id="caso.numcodigo"
                      @click="cambiarPrioridad(caso)"
                      v-if="caso.descripcionPrioridades == 'ALTA'"
                      class="
                        fas
                        fa-exclamation-circle
                        text-red-500 text-lg
                        cursor-pointer
                      "
                    ></i>
                    <i
                      :id="caso.numcodigo"
                      @click="cambiarPrioridad(caso)"
                      else
                      v-if="caso.descripcionPrioridades == 'MEDIA'"
                      class="
                        fas
                        fa-exclamation-circle
                        text-yellow-500 text-lg
                        cursor-pointer
                      "
                    ></i>
                    <i
                      :id="caso.numcodigo"
                      @click="cambiarPrioridad(caso)"
                      else
                      v-if="caso.descripcionPrioridades == 'BAJA'"
                      class="
                        fas
                        fa-exclamation-circle
                        text-green-500 text-lg
                        cursor-pointer
                      "
                    ></i>
                  </div>
                </div>
              </div>
              <div class="p-5 relative text-justify">
                <div class="pb-16">
                  <p class="text-gray-500">
                    {{ caso.descripcionReclamo }}
                  </p>
                </div>
                <div
                  class="
                    text-left
                    mt-10
                    bottom-0
                    left-0
                    absolute
                    w-auto
                    p-1
                    inline-block
                  "
                >
                  <router-link :to="'/Casos/GestionCaso/' + caso.numcodigo">
                    <button
                      class="
                        bottom-0
                        inline-block
                        left-0
                        border-1 border-gray-300
                        bg-blue-900
                        w-auto
                        font-bold
                        rounded-lg
                        text-white
                        py-1
                        px-3
                        inline-block
                      "
                    >
                      <i class="fas fa-user-cog"></i>
                      Gestión del caso
                    </button>
                  </router-link>
                </div>
                <!-- <div
                  class="text-right mt-10 bottom-0 right-0 absolute w-auto p-1 inline-block"
                >
             
                  <label
                    for="area"
                    class="inline-block mr-3 tracking-wide text-gray-800 text-right text-xs font-bold mb-2"
                    >Asignar a
                  </label>
                  <select
                    class="w-auto inline-block mr-3 bg-gray-200 border border-gray-200 text-black text-xs py-2 px-3 pr-8  rounded"
                    id="location"
                  >
                    <option
                      v-for="usuario in cargarUsuarios"
                      :key="usuario"
                      :value="usuario"
                      >{{ usuario.nombre }}</option
                    >
                  </select>
                  <button class="buttonBlue ml-3">
                    <i
                      class="em em-arrows_counterclockwise"
                      aria-role="presentation"
                      aria-label="ANTICLOCKWISE DOWNWARDS AND UPWARDS OPEN CIRCLE ARROWS"
                    ></i>
                  </button>
                </div> -->
              </div>
            </div>
            <!--/Graph Card-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import auth from "@/logic/auth";
import { mapMutations, mapState } from "vuex";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import Vue from "vue";
require("moment/locale/es");

Vue.use(VueMoment, {
  moment,
});

export default {
  name: "Solucionados",
  components: {},
  data() {
    return {
      casos: [],
      usuariosProceso: [],
      auxdata: [],
      buscardata: [],
      buscarzona: "",
      zonas: [],
      userid: false,
      tamanoPagina: 10,
      paginaActual: 1,
    };
  },
  methods: {
    ...mapMutations([
      "loadNavbar", 
      "hiddenNavbar", 
      "logueo", 
      "openNavCasos", 
      "closeNavInicio", 
      "abrirAyuda", 
      "cerrarAyuda",
      "openModalCargando",
      "closeModalCargando"
    ]),

    findtext() {
      //console.log(this.casos);
      //console.log('entro')
      let aux = this.auxdata.filter((x) =>
        x["numDocumento"].includes(this.buscardata)
      );
      //console.log(aux);
      if (this.buscardata == "") {
        this.casos = this.auxdata;
      } else {
        this.casos = aux;
      }
      if (aux.length == 0) {
        this.$alert(
          "No hay Casos que Coincidan",
          "Verifique la cedula!",
          "warning"
        );
      }
    },
    findZona() {
      //console.log(this.casos);
      //console.log('entro')
      let aux = this.auxdata.filter((x) =>
        x["codigoZona"].includes(this.buscarzona)
      );
      //  console.log(aux);
      if (this.buscarzona == "") {
        this.casos = this.auxdata;
      } else {
        this.casos = aux;
      }
      if (aux.length == 0) {
        this.$alert(
          "No hay Casos que Coincidan",
          "Verifique la cedula!",
          "warning"
        );
      }
    },
    avanzarPag(){
      if(this.paginaActual < this.cantPaginas){
        this.paginaActual = this.paginaActual + 1
      }else{
        this.$alert(
            "No Existen Mas Paginas",
              "Error!",
              "error"
          )
      }
    },
    retrocederPag(){
      if(this.paginaActual > 0 ){
        this.paginaActual = this.paginaActual - 1
      }else{
        this.$alert(
            "Estas En La Primera Pagina",
              "Error!",
              "error"
          )
      }
    },
    inicioPag(){
      this.paginaActual = 1
    },
    finPag(){
      this.paginaActual = this.cantPaginas
    }
  },
  created() {
    this.openModalCargando()
    this.openNavCasos()
    this.closeNavInicio()
    try {
      this.userid = false
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      var usuariosZonas = [194,195,196,197,198,199,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,218,221,222,228,229,230,231,232,233,234,250,251,252,253,254,255,256,257,258,259,260,261,262,263,264,265,266,267,268,269,270,271,272,273,274,275,276,277,278,279,280,281,282,283,284,285,286,287,288,289,291,292,293,294,295,296,297,298,299,300,301,302,303,304,305,307,308,309,318,319,320,321,322,323,324,325,326,327,328,329,332,333,334,335,338,339,344,345,346,347,348,350,352,353,363,364,365,366,367,368,369,370,371,372,373,374,375,376,377,379,380,397,398,399,400,401,403,404,405,409,410,411,412,413,414,424,426,430,434,435,436,437,439,442,443,444,445,446,447,448,449,456,457,458,459,462,463,464,468,469,470,485,487,488,489,490,491,492,493,503,504,505,506,507,508,509,510,511,512,513,514,502,393,392,117,174]
      if(usuariosZonas.indexOf(user.id) !== -1){
        this.userid = true
        if (this.auxayuda == false){
          this.abrirAyuda()
        }
      }
      if (user.token == "" || user.token == undefined) {
        this.$router.push("/");
      } else {
        this.display = "block";
        this.usuario = user.usuario;
        this.loadNavbar(user);
        axios
          .get(
            this.$hostname +
              "/Api/Reclamos/GetReclamosSpUsuarioCerrado/" +
              user.id,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + user.token,
              },
            }
          )
          .then((result) => {
            this.casos = result.data;
            this.casos.sort((a, b) => a.numcodigo > b.numcodigo ? 1 : -1);
            this.auxdata = this.casos;
            this.auxdata.forEach((element) => {
              element.numDocumento = element.numDocumento.toString();
            });
            var data2 = "";
            this.casos.forEach((element) => {
              var data = element.codigoZona;
              if (data != data2) {
                for (var i = 0; i < this.zonas.length; i++){
                  if (this.zonas[i] === data) {
                    this.zonas.splice(i, 1)
                  }
                }
                this.zonas.push(data)
              };
              data2 = element.codigoZona;
            });
            this.zonas.sort();
            this.closeModalCargando()
          })
          .catch((err) => {
             this.$alert(
                "Por favor vuelve a ingresar a la plataforma",
                "Expiro la Sesion!",
                "error"
            );
            this.$router.push("/");
          });
      }
    } catch (err) {
      this.$alert(
        "Por favor vuelve a ingresar a la plataforma",
        "Expiro la Sesion!",
        "error"
      );
      this.$router.push("/");
    }
  },
  computed: {
    ...mapState(["ayuda", "auxayuda"]),
    indexInicio() {
      return (this.paginaActual - 1) * this.tamanoPagina;
    },
    indexFin() {
      return this.indexInicio + this.tamanoPagina;
    },
    paginado() {
      return this.casos.slice(this.indexInicio, this.indexFin);
    },
    cantPaginas(){
      return Math.ceil(this.casos.length / this.tamanoPagina)
    },
  },
};
</script>
<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
input[type="submit"],
input[type="button"] {
  -webkit-appearance: none;
}

.buttonBlue, 
.button-blue {
  display: inline-flex;
  background: #1e3a8a;
  font-weight: bold;
  color: #fff;
  font-size: 0.813em;
  padding: 0 20px;
  height: 2.3rem;
  line-height: 35px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  outline: none;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
}

.buttonGray,
.button-Gray {
  display: inline-block;
  background: hsl(215, 0, 54%);
  font-weight: bold;
  color: #fff;
  font-size: 0.813em;
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
  border: 0;
  box-sizing: border-box;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  outline: none;
  overflow: hidden;
}
i.fas.fa-sync-alt {
    transform: scale(1.3);
}
.requestViewId {
  font-weight: bold;
  color: #bbb;
  border-radius: 0 29px 28px 0px;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 0;
  padding: 5px 18px;
  margin: 0px 20px 0 -4px;
  white-space: nowrap;
  margin-bottom: 4px;
}

/**buscador*/

.search-input {
  padding: 10px;
  border: 2px solid #111d5e;
  border-radius: 10px 0 0 10px;
  border-right: none;
  outline: none;
  font-size: 20px;
  color: rgb(0, 0, 0);
  
}
.search-button {
  text-align: center;

  outline: none;
  cursor: pointer;
  border: 2px solid #111d5e;
  border-radius: 0 10px 10px 0;
  border-left: none;
 
  font-size: 20px;
  border-left: 2px solid #111d5e;
}
.centrar{
  display: inline-flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.userlogo {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

/*buscador */
</style>
